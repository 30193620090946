<template>
    <div class="table-list">
        <el-table
            :data="tableData"
            size="medium"
            :header-cell-style="headCellStyle"
            class="customTable"
            :cell-style="cellStyle"
            :row-key="rowKey"
        >
            <el-table-column
                v-for="item in excludeOperate"
                :key="item.prop"
                :width="item.width"
                :label="item.label"
                :prop="item.prop"
                :align="item.align"
            >
                <template #default="scope">
                    <div v-if="item.isImg" class="img-box">
                        <el-image
                            class="el-img"
                            :src="scope.row[item.prop] | imgSrc(emptyImg)"
                            :alt="scope.row[imgHintKey]"
                            :title="scope.row[imgHintKey]"
                            lazy
                        >
                            <template #error>
                                <i class="el-icon-picture-outline"></i>
                            </template>
                        </el-image>
                    </div>
                    <div v-else-if="item.isSwitch" class="switch-box">
                        <el-switch
                            :value="scope.row[item.prop]"
                            active-color="#13ce66"
                            inactive-color="#e8e8e8"
                            @change="toggleSwitch(scope.row)"
                        ></el-switch>
                    </div>
                    <div v-else class="content" v-html="scope.row[item.prop]" :title="scope.row[item.prop]"></div>
                </template>
            </el-table-column>
            <el-table-column
                v-if="operateConfig"
                :label="operateConfig.label"
                :width="operateConfig.width"
                :align="operateConfig.align"
            >
                <template slot-scope="scope">
                    <slot :item="scope.row"></slot>
                </template>
            </el-table-column>
            <template #append>
                <div v-if="tableData.length <= 0" class="empty-box">
                    <el-empty :image-size="250"></el-empty>
                </div>
            </template>
        </el-table>
    </div>
</template>

<script>
export default {
    name: "TableList",
    data() {
        return {
            switchVal: false,
            cellStyle: { fontSize: "14px", color: "#332D42", height: "70px" },
            headCellStyle: { fontWeight: "normal", color: "#332D42", background: "#F6F6F6", height: "60px" },
            emptyImg: require("assets/image/blank_headPic.png"),
        };
    },
    props: {
        tableData: {
            type: Array,
            default() {
                return [];
            },
        },
        tableConfig: {
            type: Array,
            default() {
                return [];
            },
        },
        toggleSwitch: {
            type: Function,
            default() {
                return function () {};
            },
        },
        rowKey: {
            type: String,
            default: "",
        },
    },
    computed: {
        operateConfig() {
            return this.tableConfig.find((item) => item.label == "操作");
        },
        excludeOperate() {
            return this.tableConfig.filter((item) => item.label != "操作");
        },
        imgHintKey() {
            return this.tableConfig[0].prop;
        },
    },
    filters: {
        imgSrc(origin, emptyImg) {
            return origin ? origin : emptyImg;
        },
    },
    components: {},
    methods: {},
};
</script>

<style scoped lang="scss">
.table-list {
    .img-box {
        display: inline-block;
        box-sizing: border-box;
        width: 46px;
        height: 46px;
        border-radius: 50%;
        overflow: hidden;
        vertical-align: middle;
        .el-img {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            color: #909399;
            background-color: #f5f7fa;
        }
    }
    .switch-box {
        display: flex;
        align-items: center;
    }
    .content {
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}

::v-deep .customTable {
    .el-table .cell div {
        display: inline-block;
    }
    .has-gutter .el-table__cell {
        color: #332d42 !important;
        background-color: #f0f3fc !important;
    }
    .cell {
        line-height: normal;
        white-space: nowrap;
    }
    .el-table__empty-block {
        display: none;
    }
}
</style>
